import { FC, useEffect, useState } from 'react'

import ReCAPTCHA from 'react-google-recaptcha'
import { Controller, useForm } from 'react-hook-form'

import { useAppData } from 'src/hooks/useAppData'
import { ContainerUI } from 'src/components/UiKit/Container'
import { addDoc, collection } from 'firebase/firestore'
import { Dropdown } from 'src/components/UiKit/Dropdown'
import { Textarea } from 'src/components/UiKit/Textarea'
import { InputText } from 'src/components/UiKit/InputText'
import { ButtonClose } from 'src/components/UiKit/ButtonClose'
import { InputNumber } from 'src/components/UiKit/InputNumber'
import { RangeRating } from 'src/components/UiKit/RangeRating'
import { ErrorMessage } from 'src/components/UiKit/ErrorMessage'
import { ButtonLoading } from 'src/components/UiKit/ButtonLoading'
import { InputOwnershipDuration } from 'src/components/UiKit/InputOwnershipDuration'

import style from './style.module.scss'

type Props = {
	brands: string[]
	models: string[]
	bodys: string[]
	isOpen: boolean
	handleCloseModal: () => void
	handleOpenConfirmModal: () => void
	handleFilterCarChange: ({
		carBrand,
		carModel,
		carBody,
	}: {
		carBrand: string
		carModel: string
		carBody: string
	}) => void
}

const captchaToken = process.env.REACT_APP_RECAPTCHA_SITE_KEY || 'key'

/* Сообщения ошибок валидации */
const errorMessages = {
	required: 'Это поле обязательное',
}

export const ModalComment: FC<Props> = ({
	isOpen,
	handleCloseModal,
	handleOpenConfirmModal,
	handleFilterCarChange,
	bodys,
	brands,
	models,
}) => {
	const {
		handleSubmit,
		register,
		control,
		formState: { errors },
		reset,
		watch,
	} = useForm({
		defaultValues: {
			firstName: '',
			lastName: '',
			carBrand: '',
			carModel: '',
			carBody: '',
			tenure: undefined,
			mileage: undefined,
			parameterReliability: 0,
			parameterManageability: 0,
			parameterComfort: 0,
			parameterEquipment: 0,
			parameterPremium: 0,
			parameterCostEffective: 0,
			review: '',
			captcha: '',
		},
	})

	const [carBrand, carModel, carBody] = watch([
		'carBrand',
		'carModel',
		'carBody',
	])
	const [isLoading, setIsLoading] = useState(false)
	const [reqError, setReqError] = useState('')
	const { firestore } = useAppData()

	// eslint-disable-next-line
	const removeKeyFromProps = (props: any) => {
		const { captcha, ...rest } = props
		void captcha
		return {
			...rest,
			date: new Date(),
		}
	}

	// Запись данных в firestore
	async function writeData(data: object) {
		if (!firestore) {
			return
		}
		try {
			setReqError('')
			setIsLoading(true)
			await addDoc(collection(firestore, 'feedbacks'), removeKeyFromProps(data))
			handleCloseModal()
			handleOpenConfirmModal()
			setIsLoading(false)
			reset()
		} catch (error) {
			setIsLoading(false)
			setReqError('Ошибка при отправке данных')
		}
	}

	useEffect(() => {
		handleFilterCarChange({
			carBody,
			carModel,
			carBrand,
		})
	}, [carBrand, carModel, carBody])

	/* Блокировка прокрутки страницы при открытии модального окна */
	useEffect(() => {
		if (isOpen) {
			document.body.classList.add('modalOpen')
		} else {
			document.body.classList.remove('modalOpen')
		}

		return () => {
			document.body.classList.remove('modalOpen')
		}
	}, [isOpen])

	/* Если окно закрыто ничего не возвращаем */
	if (!isOpen) return null

	return (
		<div className={style.modalComment__wrapper}>
			<ContainerUI className={style.modalComment__content}>
				<ButtonClose
					className={style.modalComment__buttonClose}
					variant='white'
					onClick={handleCloseModal}
				/>

				<h2 className={style.modalComment__title}>Оставить отзыв</h2>

				<form
					className={style.modalComment__form}
					onSubmit={handleSubmit((data) => writeData(data))}
				>
					<fieldset className={style.modalComment__profile}>
						<legend className={style.modalComment__legend}>Профиль</legend>

						<InputText
							labelText='Имя'
							error={errors.firstName}
							id='firstName'
							{...register('firstName', {
								required: errorMessages.required,
								minLength: {
									value: 5,
									message: 'Минимальное количество символов — 5',
								},
								maxLength: {
									value: 20,
									message: 'Максимальное количество символов — 20',
								},
							})}
						/>

						<InputText
							labelText='Фамилия'
							error={errors.lastName}
							id='lastName'
							{...register('lastName', {
								required: errorMessages.required,
								minLength: {
									value: 5,
									message: 'Минимальное количество символов — 5',
								},
								maxLength: {
									value: 20,
									message: 'Максимальное количество символов — 20',
								},
							})}
						/>
					</fieldset>

					<fieldset className={style.modalComment__specifications}>
						<legend className={style.modalComment__legend}>
							Технические характеристики
						</legend>

						<div className={style.modalComment__dropdownWrapper}>
							<Controller
								name='carBrand'
								control={control}
								rules={{
									required: errorMessages.required,
								}}
								render={({ field }) => (
									<Dropdown
										title='Марка автомобиля'
										options={brands}
										onChange={field.onChange}
										ref={field.ref}
										id='carBrand'
										value={field.value}
										error={errors.carBrand}
									/>
								)}
							/>

							<Controller
								name='carModel'
								control={control}
								rules={{
									required: errorMessages.required,
								}}
								render={({ field }) => (
									<Dropdown
										title='Модель'
										options={models}
										onChange={field.onChange}
										ref={field.ref}
										id='carModel'
										value={field.value}
										error={errors.carModel}
									/>
								)}
							/>

							<Controller
								name='carBody'
								control={control}
								rules={{
									required: errorMessages.required,
								}}
								render={({ field }) => (
									<Dropdown
										title='Кузов'
										options={bodys}
										onChange={field.onChange}
										ref={field.ref}
										id='carBody'
										value={field.value}
										error={errors.carBody}
									/>
								)}
							/>
						</div>

						<div className={style.modalComment__numbersWrapper}>
							<Controller
								name='tenure'
								control={control}
								rules={{
									required: errorMessages.required,
									min: {
										value: 0,
										message: 'Минимальное значение — 0',
									},
									max: {
										value: 96,
										message: 'Максимальное значение — 96',
									},
								}}
								render={({ field }) => (
									<InputOwnershipDuration
										labelText='Срок владения'
										error={errors.tenure}
										onChange={field.onChange}
										ref={field.ref}
										id='tenure'
										defaultValue={field.value}
									/>
								)}
							/>

							<InputNumber
								labelText='Пробег'
								error={errors.mileage}
								id='mileage'
								{...register('mileage', {
									required: errorMessages.required,
									min: {
										value: 0,
										message: 'Минимальное значение — 0',
									},
									max: {
										value: 10000000,
										message: 'Максимальное значение — 10000000',
									},
									onChange: (e) => {
										const inputValue = e.target.value.replace(/\s/g, '') // Удаляем пробелы

										const formattedValue =
											Number(inputValue).toLocaleString('ru-RU')

										e.target.value = formattedValue
									},
								})}
							/>
						</div>
					</fieldset>

					<fieldset className={style.modalComment__parameters}>
						<legend className={style.modalComment__legend}>
							Оцените автомобиль по следующим параметрам
						</legend>

						<div className={style.modalComment__column}>
							<Controller
								name='parameterReliability'
								control={control}
								defaultValue={0}
								render={({ field }) => (
									<RangeRating
										label='Надежность'
										value={field.value}
										onChange={field.onChange}
									/>
								)}
							/>

							<Controller
								name='parameterManageability'
								control={control}
								defaultValue={0}
								render={({ field }) => (
									<RangeRating
										label='Управляемость'
										value={field.value}
										onChange={field.onChange}
									/>
								)}
							/>

							<Controller
								name='parameterComfort'
								control={control}
								defaultValue={0}
								render={({ field }) => (
									<RangeRating
										label='Комфорт'
										value={field.value}
										onChange={field.onChange}
									/>
								)}
							/>
						</div>

						<div className={style.modalComment__column}>
							<Controller
								name='parameterEquipment'
								control={control}
								defaultValue={0}
								render={({ field }) => (
									<RangeRating
										label='Оборудованность'
										value={field.value}
										onChange={field.onChange}
									/>
								)}
							/>

							<Controller
								name='parameterPremium'
								control={control}
								defaultValue={0}
								render={({ field }) => (
									<RangeRating
										label='Премиальность'
										value={field.value}
										onChange={field.onChange}
									/>
								)}
							/>

							<Controller
								name='parameterCostEffective'
								control={control}
								defaultValue={0}
								render={({ field }) => (
									<RangeRating
										label='Экономичность'
										value={field.value}
										onChange={field.onChange}
									/>
								)}
							/>
						</div>
					</fieldset>

					<fieldset>
						<Textarea
							placeholder='Напишите свой отзыв ( минимум 20 слов )'
							error={errors.review}
							{...register('review', {
								required: errorMessages.required,
								validate: (value) => {
									const minWords = 20

									const wordCount = value.trim().split(/\s+/).length

									return (
										wordCount >= minWords ||
										`Минимальное количество слов ${minWords}`
									)
								},
							})}
						/>
					</fieldset>

					<div className={style.modalComment__captchaWrapper}>
						<Controller
							name='captcha'
							control={control}
							rules={{ required: 'Подтвердите что вы не робот' }}
							render={({ field }) => (
								<ReCAPTCHA
									className={style.modalComment__captcha}
									sitekey={captchaToken}
									onChange={field.onChange}
								/>
							)}
						/>
						<ErrorMessage error={errors.captcha} />
					</div>

					<ButtonLoading
						className={style.modalComment__button}
						size='large'
						text='Отправить'
						variant='green'
						isLoading={isLoading}
						error={reqError}
					/>
				</form>
			</ContainerUI>
		</div>
	)
}
