import { FC } from 'react'

import { useNavigate } from 'react-router-dom'

import { Arrow } from 'src/components/UiKit/Arrow'
import { Stars } from 'src/components/UiKit/Stars'
import { TagRating } from 'src/components/UiKit/TagRating'
import { TCard } from 'src/types'

import classNames from 'classnames'
import calculateAverage from 'src/utils/calculateAverage'
import { extractKeyValues } from 'src/utils/extractKeyValues'
import style from './style.module.scss'

type Props = {
	data: TCard
	noFullWidth?: boolean
	className?: string
}

export const Card: FC<Props> = ({
	data: { cdn, title, comments },
	noFullWidth,
	className,
}) => {
	const url = `/models/${title.toLowerCase().replace(/\s+/g, '_')}`
	const navigate = useNavigate()
	let startX: number

	const ratingKeys = extractKeyValues(comments, 'stars', false) as number[]
	const ratingAverage = calculateAverage(ratingKeys)

	const handleMouseDown = (e: React.MouseEvent) => {
		startX = e.clientX
	}

	const handleMouseUp = (e: React.MouseEvent) => {
		const diffX = Math.abs(e.clientX - startX)
		if (diffX < 10) {
			// Только если перемещение было незначительным, выполняем переход
			navigate(url)
		}
	}

	return (
		<article
			className={classNames(style.card, {
				[style['card--no-full-width']]: noFullWidth,
				[`${className}`]: className,
			})}
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
		>
			<TagRating className={style.card__rating} rating={ratingAverage} />
			<img className={style.card__image} src={cdn} alt={title} />
			<span className={style.card__title}>{title}</span>
			<Stars className={style.card__stars} rating={ratingAverage} />
			<div className={style.card__link}>
				<span>Подробнее</span>
				<Arrow />
			</div>
		</article>
	)
}
