import { FC, useEffect, useState } from 'react'

import classNames from 'classnames'
import { useParams } from 'react-router-dom'

import { Banner } from 'src/components/Banner'
import { CharCar } from 'src/components/CharCar'
import { FooterLogo } from 'src/components/Footer'
import { Header } from 'src/components/Header'
import { HeroCar } from 'src/components/HeroCar'
import { ModalComment } from 'src/components/ModalComment'
import { OtherCars } from 'src/components/OtherCars'
import { RatingCar } from 'src/components/RatingCar'
import { Reviews } from 'src/components/Reviews'
import { ScoresCar } from 'src/components/ScoresCar'
import { ContainerUI } from 'src/components/UiKit/Container'
import { MainLayout } from 'src/components/UiKit/MainLayout'
import { SliderCars } from 'src/components/UiKit/SliderCars'
import mockCards from 'src/mocks/cards.json'
import { TCard, TCarScores, TFilterCars } from 'src/types'
import { extractKeyValues } from 'src/utils/extractKeyValues'

import ModalConfirmation from 'src/components/ModalConfirmation'
import style from 'src/styles/page.module.scss'
import calculateAverage from 'src/utils/calculateAverage'
import { toUrlName } from 'src/utils/toUrlName'
import { filterCars } from 'src/utils/filterCars'

export const Models: FC = () => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false)
	const { model } = useParams()
	const [filterCarState, setFilterCarState] = useState<TCard[]>([])

	const car = mockCards.find((card) => card.title === toUrlName(model)) as TCard

	const models = extractKeyValues(filterCarState, 'title') as string[]
	const brands = extractKeyValues(filterCarState, 'brand') as string[]
	const bodys = extractKeyValues(filterCarState, 'body') as string[]

	/* Расчет среднего рейтинга */
	const carRatingKeys = extractKeyValues(
		car.comments,
		'stars',
		false
	) as number[]
	const carRatingAverage = calculateAverage(carRatingKeys)

	/* Рассчет в среднем параметров */
	const carScoreKeys = extractKeyValues(
		car.comments,
		'carScores',
		false
	) as TCarScores[]
	const carScoreAverage: TCarScores = {
		comfort: calculateAverage(carScoreKeys.map((item) => item.comfort)),
		premium: calculateAverage(carScoreKeys.map((item) => item.premium)),
		equipment: calculateAverage(carScoreKeys.map((item) => item.equipment)),
		reliability: calculateAverage(carScoreKeys.map((item) => item.reliability)),
		manageability: calculateAverage(
			carScoreKeys.map((item) => item.manageability)
		),
		costEffectiveness: calculateAverage(
			carScoreKeys.map((item) => item.costEffectiveness)
		),
	}

	const handleOpenModal = () => setIsModalOpen(true)
	const handleOpenModalConfirm = () => setIsModalConfirmOpen(true)

	const handleChangeFilterCars = ({
		carBrand,
		carModel,
		carBody,
	}: {
		carBrand: string
		carModel: string
		carBody: string
	}) => {
		const filterData = filterCars(
			{
				brand: carBrand,
				model: carModel,
				body: carBody,
				search: '',
				ratingRange: [0, 5],
			} as TFilterCars,
			mockCards as TCard[]
		)
		setFilterCarState(filterData)
	}

	/* При изменении данных машины выполняем scroll на верх */
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [car])

	useEffect(() => {
		setFilterCarState(mockCards as TCard[])
	}, [])

	return (
		<MainLayout>
			<Header />

			<ModalComment
				isOpen={isModalOpen}
				handleCloseModal={() => setIsModalOpen(false)}
				bodys={bodys}
				brands={brands}
				models={models}
				handleOpenConfirmModal={handleOpenModalConfirm}
				handleFilterCarChange={handleChangeFilterCars}
			/>

			<ModalConfirmation
				handleCloseModal={() => setIsModalConfirmOpen(false)}
				isOpen={isModalConfirmOpen}
			/>

			<main className={style.main}>
				<section className={style.main__section}>
					<HeroCar
						carName={car.title}
						rating={carRatingAverage}
						wallpaper={car.wallpaper}
						handleOpenModal={handleOpenModal}
					/>
				</section>

				<section
					className={classNames(
						style.main__section,
						style.section__carProperty
					)}
				>
					<RatingCar rating={carRatingAverage} />
					<ScoresCar scores={carScoreAverage} />
				</section>

				<section className={style.main__section}>
					<SliderCars images={car.slides} />
				</section>

				<section className={style.main__section}>
					<CharCar data={car.parameters} />
				</section>

				<section
					className={classNames(
						style.main__section,
						style['main__section--no-center']
					)}
				>
					<Banner handleOpenModal={handleOpenModal} />
				</section>

				<section className={style.main__section}>
					<ContainerUI>
						<Reviews commentsProps={car.comments} />
					</ContainerUI>
				</section>

				<section className={style.main__section}>
					<OtherCars data={mockCards as TCard[]} />
				</section>
			</main>

			<FooterLogo />
		</MainLayout>
	)
}
